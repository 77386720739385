import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectFilterLookups (Vue) {
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    let apiCalls = []
    apiCalls.push({ name: 'selectGrupoCliente', method: 'grupoCliente.select', params: { filter: apiFilterEstadoActivo } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectCliente (Vue, filter, search, sorter, page, mapBounds, pageSize) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.cliente.searchFilter(search))
    }
    if (filter.clienteNombre.value) {
      apiFilter.addILike(filter.clienteNombre.field, filter.clienteNombre.value)
    }
    if (filter.razonSocial.value) {
      apiFilter.addILike(filter.razonSocial.field, filter.razonSocial.value)
    }
    if (filter.cif.value) {
      apiFilter.addILike(filter.cif.field, filter.cif.value)
    }
    if (filter.grupoCliente.value && filter.grupoCliente.value.length > 0) {
      apiFilter.addIn(filter.grupoCliente.field, filter.grupoCliente.value)
    }
    if (mapBounds) {
      apiFilter.addGT('latitud', mapBounds.swLat)
      apiFilter.addLT('latitud', mapBounds.neLat)
      apiFilter.addGT('longitud', mapBounds.swLng)
      apiFilter.addLT('longitud', mapBounds.neLng)
    }
    const resp = await Vue.$api.call('cliente.selectList', {
      page,
      filter: apiFilter,
      sorter,
      page_size: pageSize,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectClienteRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idcliente', pks)
    const resp = await Vue.$api.call('cliente.selectList', {
      filter: apiFilter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
